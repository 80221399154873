import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CloseSessionModal } from "./index";
import {
  cart_icon,
  cart_icon_white,
  dashboard_icon,
  dashboard_icon_black,
  logo,
  logout_icon,
  session_icon_black,
  session_icon_white,
} from "../assets";
import { useSelector } from "react-redux";
import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "./Modal/language-switcher";
import { clearDraftOrders } from "../Redux/Action/draftOrdersAction";
import { useDispatch } from "react-redux";
import { clearProducts } from "../Redux/Action/productsAction";
import ConfirmLanguageChange from "./Modal/ConfirmLanguageChange";
import CustomListbox from "./Modal/CustomListBox";
import ShortcutsModal from "./Modal/ShortcutKeys_Modal";
import { clearCustomer } from "../Redux/Action/getcustomerAction";
import { clearReturnProducts } from "../Redux/Action/returnproductAction";

const LoadingSpinner = () => {
  return (
    <div
      style={{ zIndex: 50 }}
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 backdrop-blur"
    >
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

const CloseSessionButton = ({ openModal }) => {
  const { t, i18n } = useTranslation();
  return (
    <button
      onClick={openModal}
      className=" border-0  px-4 rounded-[100px] items-center flex justify-center w-[100%] py-2.5 font-semibold bg-gradient-to-b from-[#FF8C8C] to-[#F42020]  text-white"
    >
      <img
        loading="lazy"
        src={logout_icon}
        alt="Logout Icon"
        className={`shrink-0 self-start mx-2 w-6 aspect-[0.96] mt-0.5 transition-transform transform ${
          i18n.language == "ar" ? "scale-x-[-1]" : ""
        }`}
      />
      <div className="">{t("Close")}</div>
    </button>
  );
};

const Navbar = ({ closeSessionModal }) => {
  const { t, i18n } = useTranslation();
  const [selectedItem, setSelectedItem] = useState("");
  const [userData, setUserData] = useState(null);
  const [langOptions, setLangOptions] = useState({});
  const [langModal, setOpenLangModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state?.login?.user?.result);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isShortcutsModalOpen, setIsShortcutsModalOpen] = useState(false);
  const [userDataFetched, setUserDataFetched] = useState(false);


  const [nextLanguage, setNextLanguage] = useState(null);

  const handleUserContainerClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (!userDataFetched) {
      getUserData();
      setUserDataFetched(true);
    }
  }, []);

  useEffect(() => {
    // getUserData();
    const match = pathname.substring(1, 12);
    // console.log("SUB", match);
    if (match == "orders") {
      handleItemClick("Orders");
    } else if (match == "session") {
      handleItemClick("Session");
      // getUserData();
    } else if (match == "dashboard") {
      handleItemClick("Dashboard");
    }
  }, [location, user, pathname]);

  useEffect(() => {
    // Determine the next language based on the current language
    if (i18n.language === "en") {
      setNextLanguage("ar");
    } else {
      setNextLanguage("en");
    }
  }, [i18n.language]);

  const openLanguageChangeModal = () => {
    if (nextLanguage) {
      setLangOptions({
        value: nextLanguage,
        label: nextLanguage === "en" ? "English" : "Arabic",
      });
      setOpenLangModal(true);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.key.toLowerCase();
      const normalizedKey =
        {
          أ: "h", // Arabic "ض" is similar to English "H"
          "/": "l", // Arabic "ل" is similar to English "L"
          "]": "d", // Arabic "د" is similar to English "D"
          "ٍ": "s", // Arabic "س" is similar to English "S"
          "×": "o", // Arabic "ط" is similar to English "O"
          "ُ": "e", // Arabic "ع" is similar to English "E"
        }[key] || key; // Default to the original key if no match

      if (e.shiftKey) {
        switch (normalizedKey) {
          case "h":
            e.preventDefault();
            setOpenLangModal(false);
            setIsShortcutsModalOpen(true);
            break;
          case "l":
            e.preventDefault();
            setIsShortcutsModalOpen(false);
            openLanguageChangeModal();
            break;
          case "d":
            e.preventDefault();
            navigate("/dashboard");
            break;
          case "s":
            e.preventDefault();
            navigate("/session");
            break;
          case "o":
            e.preventDefault();
            navigate("/orders");
            break;
          case "e":
            e.preventDefault();
            openCloseSessionModal();
            localStorage.setItem("eKeyPressed", true);
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate, nextLanguage]);


  const getUserData = async () => { 
    if (!userData){
    const response = await axiosInstance.post(URLS.GET_USER_DETAILS, {
      user_id: user.user_id,
    });
    if (response?.data.result.status_code === 200) {
      setUserData(response?.data.result);
    }
  }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const [isCloseSessionModalOpen, setIsCloseSessionModalOpen] = useState(false);

  const openCloseSessionModal = () => {
    setIsCloseSessionModalOpen(true);
  };

  const closeCloseSessionModal = () => {
    setIsCloseSessionModalOpen(false);
  };

  const logout = async () => {
    // console.log("log");
    localStorage.clear();
    window.location.reload();
  };

  const handleLangChange = async (option) => {
    if (option.value != i18n.language) {
      setLangOptions(option);
      setOpenLangModal(true);
    }
  };
  const ConfirmLanguage = async () => {
    setOpenLangModal(false);
    if (langOptions.value != i18n.language) {
      setLoading(true);
      await axiosInstance.post(URLS.CHANGE_LANGUAGE, {
        code: langOptions.value,
      });
      i18n.changeLanguage(langOptions.value); // Change language using i18next
      dispatch(clearDraftOrders());
      dispatch(clearProducts());
      dispatch(clearReturnProducts());
      dispatch(clearCustomer());
      localStorage.setItem("selectedLanguage", langOptions.value); // Save selected language to localStorage
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  return (
    <>
      {isCloseSessionModalOpen && (
        <CloseSessionModal
          closeModal={closeCloseSessionModal}
          handleClose={() => logout()}
        />
      )}
      {langModal && (
        <ConfirmLanguageChange
          onClose={() => {
            setOpenLangModal(false);
            setLangOptions({});
          }}
          confirm={ConfirmLanguage}
          selectedLanguage={langOptions.value}
        />
      )}
      {isShortcutsModalOpen && (
        <ShortcutsModal
          isOpen={isShortcutsModalOpen}
          onClose={() => setIsShortcutsModalOpen(false)}
        />
      )}
      {loading && <LoadingSpinner />}
      <div className="navbar">
        <div className="flex flex-wrap gap-5 justify-between px-8 py-1.5 max-md:px-5 shadow-lg">
          <div className="flex flex-wrap gap-5">
            <div className="flex gap-2.5 my-auto">
              <img
                loading="lazy"
                src={logo}
                alt="logo"
                className="shrink-0 aspect-[1.05] ml-auto w-[45px]"
              />
              <div className="text-3xl h-[25px] mt-2 font-semibold text-slate-800">
                {t("Cashier")}
              </div>
            </div>
            <div className="flex flex-wrap gap-2 justify-center text-xl font-semibold tracking-wide capitalize whitespace-nowrap text-zinc-800">
              <Link to="/dashboard" className="nav-link">
                <div
                  className={`flex flex-col justify-center ${
                    selectedItem === "Dashboard"
                      ? "bg-[#CD9C00] rounded-xl"
                      : "rounded-xl bg-[#EEEEEE] dark:hover:bg-[#ffffff47] bg-opacity-25"
                  }`}
                  onClick={() => handleItemClick("Dashboard")}
                >
                  <div className="flex flex-col justify-center px-5 py-5 w-full rounded-xl">
                    <div className="flex gap-2 items-center">
                      {selectedItem === "Dashboard" ? (
                        <img
                          loading="lazy"
                          src={dashboard_icon}
                          alt="Dashboard Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-white"
                        />
                      ) : (
                        <img
                          loading="lazy"
                          src={dashboard_icon_black}
                          alt="Dashboard Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-black"
                        />
                      )}
                      <div
                        className={`${
                          selectedItem === "Dashboard"
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {t("Dashboard")}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/session" className="nav-link">
                <div
                  className={`flex flex-col justify-center ${
                    selectedItem === "Session"
                      ? "bg-[#CD9C00] rounded-xl"
                      : "rounded-xl bg-[#EEEEEE] dark:hover:bg-[#ffffff47] bg-opacity-25"
                  }`}
                  onClick={() => handleItemClick("Session")}
                >
                  <div className="flex flex-col justify-center px-10 py-5 w-full rounded-xl">
                    <div className="flex gap-2 items-center">
                      {selectedItem === "Session" ? (
                        <img
                          loading="lazy"
                          src={session_icon_white}
                          alt="Session Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-white"
                        />
                      ) : (
                        <img
                          loading="lazy"
                          src={session_icon_black}
                          alt="Session Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-black"
                        />
                      )}
                      <div
                        className={`${
                          selectedItem === "Session"
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {t("Session")}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/orders" className="nav-link">
                <div
                  className={`flex flex-col justify-center ${
                    selectedItem === "Orders"
                      ? "bg-[#CD9C00] rounded-xl"
                      : "rounded-xl bg-[#EEEEEE] dark:hover:bg-[#ffffff47] bg-opacity-25"
                  }`}
                  onClick={() => handleItemClick("Orders")}
                >
                  <div className="flex flex-col justify-center px-11 py-5 w-full rounded-xl">
                    <div className="flex gap-2 items-center">
                      {selectedItem === "Orders" ? (
                        <img
                          loading="lazy"
                          src={cart_icon_white}
                          alt="Cart Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-white"
                        />
                      ) : (
                        <img
                          loading="lazy"
                          src={cart_icon}
                          alt="Cart Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-black"
                        />
                      )}
                      <div
                        className={`${
                          selectedItem === "Orders"
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {t("Orders")}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="flex gap-5 justify-between items-center my-auto text-lg">
            <div className="">
              <LanguageSwitcher
                handleLangChange={handleLangChange}
                theme="light"
              />
            </div>
            <div className="flex gap-5 justify-between items-center">
              <div className="flex items-center py-1.5 px-3  font-medium border border-solid bg-white bg-opacity-20 border-slate-200 rounded-[100px] text-zinc-800">
                <CustomListbox
                  userData={userData}
                  handleDropdownChange={handleUserContainerClick}
                  selectedItem={isDropdownOpen ? "Shortcuts" : null}
                />
              </div>
              <div>
                <CloseSessionButton openModal={openCloseSessionModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";


export const ADD_ALL_PRODUCTS = 'ADD_ALL_PRODUCTS';
export const CLEAR_ALL_PRODUCTS = 'CLEAR_ALL_PRODUCTS';
export const UPDATE_FAV_PRODUCT = 'UPDATE_FAV_PRODUCT';
export const UPDATE_ALL_PRODUCTS = 'UPDATE_ALL_PRODUCTS'; // Add this line


export const addAllProducts = (products) => ({
  type: ADD_ALL_PRODUCTS,
  payload: products,
});

export const clearALLProducts = () => ({
  type: CLEAR_ALL_PRODUCTS,
});


export const UpdateFav = (products) => ({
  type: UPDATE_FAV_PRODUCT,
  payload: products,
});


export const fetchAllProducts = (companyId, lang) => async (dispatch) => {
    try {
      const response = await axiosInstance.post(URLS.GET_ALL_PROD, {
        company_id: companyId,
        lang: lang,
      });
      dispatch(addAllProducts(response?.data?.result[0]?.products));
    } catch (error) {
      console.error('Error fetching all products', error);
    }
  };

export const updateAllProducts = (finalProductList) => ({
  type: UPDATE_ALL_PRODUCTS,
  payload: finalProductList,
});

// export const ADD_ALL_PRODUCTS = 'ADD_ALL_PRODUCTS';
// export const CLEAR_ALL_PRODUCTS = 'CLEAR_ALL_PRODUCTS';
// export const UPDATE_FAV_PRODUCT = 'UPDATE_FAV_PRODUCT';


// export const addAllProducts = (products) => ({
//   type: ADD_ALL_PRODUCTS,
//   payload: products,
// });

// export const clearALLProducts = () => ({
//   type: CLEAR_ALL_PRODUCTS,
// });


// export const UpdateFav = (products) => ({
//   type: UPDATE_FAV_PRODUCT,
//   payload: products,
// });


// export const fetchAllProducts = (companyId, lang) => async (dispatch) => {
//     try {
//       const response = await axiosInstance.post(URLS.GET_ALL_PROD, {
//         company_id: companyId,
//         lang: lang,
//       });
//       dispatch(addAllProducts(response?.data?.result[0]?.products));
//     } catch (error) {
//       console.error('Error fetching all products', error);
//     }
//   };

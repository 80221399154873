// categoriesActions.js
import { useSelector } from 'react-redux';
import axiosInstance from '../../AxiosInstance';
import { URLS } from '../../API/API endpoints';

export const fetchCategories = (companyId) => async (dispatch) => {
  dispatch({ type: 'FETCH_CATEGORIES_REQUEST' });
  try {
    const response = await axiosInstance.post(URLS.GET_CATEGORIES, {
      company_id: companyId,
    });
    if (response?.data?.result[0]?.status_code === 200) {
      dispatch({ type: 'FETCH_CATEGORIES_SUCCESS', payload: response?.data?.result[0]?.response });
    } else {
      dispatch({ type: 'FETCH_CATEGORIES_FAILURE', payload: response?.data?.result[0]?.error });
    }
  } catch (error) {
    dispatch({ type: 'FETCH_CATEGORIES_FAILURE', payload: error.message });
  }
};
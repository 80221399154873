import { ADD_ALL_PRODUCTS, CLEAR_ALL_PRODUCTS, UPDATE_FAV_PRODUCT, UPDATE_ALL_PRODUCTS } from "../Action/allProducts";

const initialState = {
  allProducts: [],
  error: null,
};

const allProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALL_PRODUCTS:
      return { ...state, allProducts: action.payload };
    case CLEAR_ALL_PRODUCTS:
      return { ...state, allProducts: [] };
    case UPDATE_FAV_PRODUCT:
      return {
        ...state,
        allProducts: state.allProducts.map((product) => {
          return product.prod_id === action.payload.prod_id ? action.payload : product;
        }),
      };
    case UPDATE_ALL_PRODUCTS:
      return { ...state, allProducts: action.payload };
    default:
      return state;
  }
};

export const updateAllProducts = (updatedAllProducts) => {
  return {
    type: UPDATE_ALL_PRODUCTS,
    payload: updatedAllProducts,
  };
};


export default allProductsReducer;

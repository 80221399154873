import React, { useState, useMemo, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import {
  add_icon,
  barcode_icon,
  filter_icon,
  search_icon,
  substract_icon,
  view_order_icon,
} from "../../assets";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectDraftOrder } from "../../Redux/Action/draftOrdersAction";
import { addProducts } from "../../Redux/Action/productsAction";
import { useDispatch } from "react-redux";
import { setCustomer } from "../../Redux/Action/getcustomerAction";
import { clearReturnProducts } from "../../Redux/Action/returnproductAction";

function ContinueButton({ onClick, selectedOrder }) {
  const { t, i18n } = useTranslation();

  const handleContinue = () => {
    onClick();
  };

  return (
    <button
      onClick={handleContinue}
      className="flex flex-row justify-center font-semibold text-xl item-center px-40 py-4 mt-5 rounded-[5px] shadow-sm bg-[#1b4597] text-white"
    >
      <div>{t("Continue")}</div>
    </button>
  );
}

function DraftProductList({ product }) {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === "ar";


  return (
    <div className="flex items-center gap-5 p-3 text-base border-b border-solid border-slate-200 cursor-pointer">
      <div className="flex items-center gap-3.5 font-medium text-zinc-800">
        <img
          loading="lazy"
          src={product.image_url}
          alt={product.name}
          className="shrink-0 aspect-square object-contain w-[80px]"
        />
        <div className="flex flex-col">
          <div className="my-auto font-semibold text-neutral-700">
            {product.name}
          </div>
          {/* <div className="my-auto text-zinc-900 text-opacity-70">
            {t("Price")} : {product.sale_price} | {t("QTY")} : {product.quantity}
          </div> */}
          <div
           style={{ direction: "ltr", textAlign: isRTL ? "right" : "left" }}
          className="my-auto text-zinc-800 text-opacity-70">
              {`${product.quantity} X EGP ${product.sale_price}`}
          </div>
          {/* <div className="my-auto text-zinc-800 text-opacity-70">
                        QTY: {product.quantity}
                    </div> */}
        </div>
      </div>
    </div>
  );
}

function DraftOrdersTable({ draftOrders, onRowClick }) {
  const { t, i18n } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [pagination, setPagination] = useState(0);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.login);
  const navigate = useNavigate();
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(-1);
  const isRTL = i18n.language === "ar";
  
  const sortedDraftOrders = useMemo(() => {
    return [...draftOrders].sort((a, b) => {
      const dateA = new Date(`${a.date}T${a.time}`);
      const dateB = new Date(`${b.date}T${b.time}`);
      return dateB - dateA;
    });
  }, [draftOrders]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: t("Date"),
        accessor: "date",
        Cell: ({ row }) => {
          const { date, time } = row.original;
          return (
            <div className="text-ellipsis">
              {date} {time}
            </div>
          );
        },
      },
      {
        Header: t("Customer"),
        accessor: "customer",
        Cell: ({ row }) => {
          const { customer } = row.original;
          // console.log("customer------------->", customer);
          const truncatedName =
            customer?.userName && customer?.userName?.length > 12
              ? customer?.userName.substring(0, 12) + "..."
              : customer?.userName;
          return (
            <div className="text-ellipsis">
              {customer?.mobile ? truncatedName : "-"}{" "}
              {customer?.mobile && `(${customer?.mobile})`}
            </div>
          );
        },
      },
      {
        Header: t("No. Of Items"),
        accessor: "items",
        Cell: ({ value }) => <div>{Object.keys(value).length}</div>,
      },
      {
        Header: t("Total"),
        accessor: "total",
        Cell: ({ value }) => <div>{`${value} ${user?.currency || "EGP"}`}</div>,
      },
    ];

    if (!isDrawerOpen) {
      baseColumns.push({
        Header: " ",
        Cell: ({ row }) => (
          <button onClick={() => handleRowClick(row.original)}>
            <img
              src={view_order_icon}
              alt="view_order_icon"
              className={`w-8 h-4 transition-transform transform ${
                i18n.language == "ar" ? "scale-x-[-1]" : ""
              }`}
            />
          </button>
        ),
      });
    }

    return baseColumns;
  }, [isDrawerOpen, user?.currency]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: sortedDraftOrders,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );
//   const handleRowClick = (order) => {
//     setSelectedOrder(order);
//     setIsDrawerOpen(true);
//     // onRowClick(order);
//   };

const handleRowClick = (index, order) => {
    // console.log("Row clicked:", order); // Debugging
    setSelectedOrder(order);
    setSelectedOrderIndex(index);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedOrder(null);
  };
  
  const handleContinue = () => {
    // console.log("CHLAAA CONTINUE");
    const selectedOrder = sortedDraftOrders[selectedOrderIndex];
    // console.log("Selected order in continue:", selectedOrder); // Debugging
    if (selectedOrder) {
      dispatch(setCustomer(selectedOrder.customer));
      dispatch(addProducts(selectedOrder.items));
      dispatch(selectDraftOrder(selectedOrder.id));
      dispatch(clearReturnProducts());
      navigate("/session");
    } else {
      console.error("No selected order found!"); // Error log
    }
  };

  const handleKeyDown = (e) => {
    console.log(`Key pressed: ${e.key}, Ctrl: ${e.ctrlKey}`);
    
    if (e.key === "ArrowUp") {
      setSelectedOrderIndex((prev) => Math.max(prev - 1, 0));
    } else if (e.key === "ArrowDown") {
      setSelectedOrderIndex((prev) => Math.min(prev + 1, sortedDraftOrders.length - 1));
    } else if (e.key === "Enter" && !e.ctrlKey) {
      const selectedOrder = sortedDraftOrders[selectedOrderIndex];
      if (selectedOrder) {
        handleRowClick(selectedOrderIndex, selectedOrder);
      }
    } else if (e.ctrlKey && e.key === "Enter") {
      handleContinue(); 
    } else if (e.key === "Escape") {
      closeDrawer(); 
    }
  };
  
  useEffect(() => {
    const handleKeyPress = (e) => handleKeyDown(e);
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedOrderIndex, sortedDraftOrders]);

  return (
    <>
      <div className="flex bg-transparent">
        <div
          className={`transition-all bg-white rounded-[15px] overflow-hidden duration-300 ${
            isDrawerOpen ? "flex-grow me-[33%]" : "flex-grow w-full"
          }`}
        >
          <table {...getTableProps()} className="w-full cursor-pointer">
            <thead className="px-10 py-4 w-full font-semibold text-lg tracking-wide text-center capitalize whitespace-nowrap bg-[#C9ECF7] text-zinc-800 max-md:px-5 max-md:max-w-full">
              <tr>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="px-6 py-[2px] text-center"
                    >
                      {column.render("Header")}
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                const isSelected = selectedOrderIndex === i;
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => handleRowClick(i, row.original)}
                    className={`gap-5 self-center mt-6 w-full tracking-wide capitalize max-w-[983px] text-zinc-800 max-md:flex-wrap max-md:max-w-full ${
                      isSelected ? "bg-gray-200" : ""
                    }`}
                    style={{ borderBottom: "1px solid #ccc" }}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-6 py-3 whitespace-nowrap text-center"
                      >
                        <div className="text-lg text-gray-900">
                          {cell.render("Cell")}
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <footer className="flex justify-center text-lg self-center px-5 my-2 w-full">
            {/* <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value));
                            }}
                            className="px-3 py-1 rounded-lg border border-gray-100 font-semibold focus:outline-none"
                        >
                            {[5, 10, 15].map(pageSize => (
                                <option className="font-semibold" key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select> */}
            <div className="flex gap-5 text-center text-black font-semibold w-2/12">
              <button
                onClick={() => setPagination((prevValue) => prevValue - 10)}
                disabled={pagination <= 0}
                className="p-2 rounded-full bg-gray-200"
                style={{ width: "40px", height: "40px" }}
              >
                &lt;
              </button>
              <div className="flex-auto my-auto">
                {t("Results")}{" "}
                <strong>
                  {pagination + 1}-{pagination + draftOrders?.length}
                </strong>
              </div>
              <button
                onClick={() => setPagination((prevValue) => prevValue + 10)}
                disabled={draftOrders?.length < 10}
                className="p-2 rounded-full bg-gray-200"
                style={{ width: "40px", height: "40px" }}
              >
                &gt;
              </button>
            </div>
            {/* <form
                            className="flex gap-4"
                            onSubmit={(e) => {
                                e.preventDefault();
                                const page = e.target.pageInput.value
                                    ? Number(e.target.pageInput.value) - 1
                                    : 0;
                                gotoPage(page);
                            }}
                        >
                            <label htmlFor="pageInput" className="my-auto text-black font-semibold">
                                Go to:
                            </label>
                            <input
                                type="text"
                                id="pageInput"
                                placeholder="Eg: 14"
                                aria-label="Go to page"
                                className="px-3 pt-2.5 pb-1 font-semibold rounded-lg bg-zinc-100 text-neutral-400 w-[75px] focus:outline-none"
                            />
                        </form> */}
          </footer>
          {isDrawerOpen && (
            <>
              <div
                className={`absolute flex-auto top-0 mt-20 custom-scrollbar overflow-y-auto end-0 h-[calc(140vh-4rem)] bg-white shadow-lg z-10 p-5 transition-transform transform ${
                  isDrawerOpen
                    ? "translate-x-0 w-[32%]"
                    : "translate-x-full w-0"
                }`}
              >
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-bold text-blue-900">
                    {t("Draft Order Details")}
                  </h2>
                  <button
                    onClick={closeDrawer}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                {/* <div className="mt-8 flex justify-between text-base"> */}
                {/* <div className="mt-6 text-base text-right"> */}
                <div className={`mt-6 text-base ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                {selectedOrderIndex >= 0 && (
                    <>
                      <p>
                        <strong>{t("Customer Name :")}</strong>{" "}
                        {
                          sortedDraftOrders[selectedOrderIndex]?.customer
                            ?.userName
                        }
                      </p>
                      <p>
                        <strong>{t("Customer Phone No.:")}</strong>{" "}
                        {
                          sortedDraftOrders[selectedOrderIndex]?.customer
                            ?.mobile
                        }
                      </p>
                      <p>
                        <strong>{t("Total Amount :")}</strong>{" "}
                        {sortedDraftOrders[selectedOrderIndex]?.total}
                      </p>
                      <p className="mb-2">
                        <strong>{t("Number of Items :")}</strong>{" "}
                        {
                          Object.keys(
                            sortedDraftOrders[selectedOrderIndex]?.items
                          ).length
                        }
                      </p>
                    </>
                  )}
                </div>
                <h3 className={`text-lg font-semibold mb-2 mt-4 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {t("Product Details")}
                </h3>
                <div className="mt-6 overflow-y-auto xl:max-h-[calc(90vh-4rem)] lg:max-h-[calc(82vh-4rem)] custom-scrollbar">
                  {selectedOrderIndex >= 0 &&
                    Object.values(
                      sortedDraftOrders[selectedOrderIndex]?.items
                    ).map((product, index) => (
                      <DraftProductList key={index} product={product} />
                    ))}
                </div>
                <div className="flex w-[40%] item-center justify-center ps-[48%] fixed bottom-5 font-semibold text-lg">
                  <ContinueButton onClick={handleContinue} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DraftOrdersTable;

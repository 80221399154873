import React, { useState, useRef, useEffect } from "react";
import {
  return_icon,
  plus_icon,
  star_icon,
  right_scroll,
  left_scroll,
  refresh_icon,
} from "../../assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProductBox } from "./sessioncomponents/ProductBox";
import ProductSearchBar from "./sessioncomponents/ProductSearchBar";
import ProductGrid from "./sessioncomponents/ProductGrid";
import { URLS } from "../../API/API endpoints";
import { useSelector } from "react-redux";
import axiosInstance from "../../AxiosInstance";
import OpeningCash_Modal from "../Modal/OpeningCash_Modal";
import {
  getcategorydata,
  sessionstatesave,
} from "../../Redux/Action/sessionidentifierAction";
import { useDispatch } from "react-redux";
// import { debounce } from 'lodash';
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { addProducts, clearProducts } from "../../Redux/Action/productsAction";
import BarcodeReader from "react-barcode-reader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { addDraftOrder } from "../../Redux/Action/draftOrdersAction";
import { clearCustomer } from "../../Redux/Action/getcustomerAction";
import { addAllProducts } from "../../Redux/Action/allProducts";
import { clearReturnProducts } from "../../Redux/Action/returnproductAction";
import { formGroupClasses } from "@mui/material";
import { fetchCategories } from "../../Redux/Action/categoriesAcions";
import { updateAllProducts } from "../../Redux/Reducers/allProductsReducer";

const ProdLoadingSpinner = ({ message }) => {
  return (
    <div
      style={{ zIndex: 50 }}
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-90 backdrop-blur"
    >
      <div className="flex flex-col items-center">
        <div className="text-lg text-white font-bold">{message}</div>
        <div className=" flex justify-center">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReturnButton = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/orders?refundorder`, { state: { forRefund: true } });
    // navigate(`/orders?refundorder`);
  };
  return (
    <div
      onClick={handleClick}
      className="flex justify-center w-[21vh] py-2 font-semibold bg-gradient-to-b from-red-400 via-red-500 to-red-500 text-white whitespace-nowrap rounded-lg cursor-pointer"
    >
      <img
        src={return_icon}
        alt="return_icon"
        className="shrink-0 self-start w-6 aspect-[0.96]"
      />
      <div className="mt-0.5 ml-1">{t("Return")}</div>
    </div>
  );
};

const NewOrderButton = ({ onClick }) => {
  const { t, i18n } = useTranslation();
  return (
    // Pass onClick prop to handleNewOrderClick
    <div
      onClick={onClick}
      className="flex justify-center w-[30vh] py-2 font-semibold bg-gradient-to-b from-blue-800 via-blue-900 to-blue-900 text-white whitespace-nowrap rounded-lg cursor-pointer"
    >
      {/* <img
        src={plus_icon}
        alt="plus_icon"
        className=" mt-1 shrink-0 self-start w-5 aspect-[0.96]"
      /> */}
      <img
        src={plus_icon}
        alt="plus_icon"
        className=" mt-1 shrink-0 self-start w-5 aspect-[0.96]"
      />
      <div className="mt-0.5 ml-1">{t("New Order")}</div>
    </div>
  );
};

// const CategoryButton = ({ children, active, onClick }) => (
//   <button
//     className={`flex-none justify-center px-5 py-1 mt-2 border border-solid border-stone-300 rounded-lg font-bold shadow-sm mr-2 ${
//       active ? "text-white bg-[#76CFEE]" : "text-gray-600 bg-white"
//     }`}
//     onClick={onClick}
//   >
//     {children}
//   </button>
// );

const CategoryButton = ({ children, active, highlighted, onClick }) => (
  <button
    className={`flex-none justify-center px-5 py-2 mt-2 border border-solid border-stone-300 rounded-lg font-bold shadow-sm mr-2 ${
      active
        ? "text-white bg-[#76CFEE]"
        : highlighted
        ? "text-gray-600 bg-[#E0F7FA]"
        : "text-gray-600 bg-white"
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);

function CategorySkeleton() {
  return (
    <Stack direction="row" spacing={1} sx={{ marginTop: "0.5rem" }}>
      <Skeleton variant="rounded" width={60} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
    </Stack>
  );
}

function ProductGridSkeleton() {
  const rows = 3;
  const columns = 6;

  return (
    <div>
      {[...Array(rows)].map((_, rowIndex) => (
        <Stack
          key={rowIndex}
          direction="row"
          spacing={1}
          sx={{ marginTop: rowIndex === 0 ? "1rem" : "1rem" }}
        >
          {[...Array(columns)].map((_, colIndex) => (
            <Stack
              key={colIndex}
              spacing={1}
              sx={{ marginLeft: "10px !important" }}
            >
              <Skeleton variant="rounded" width={165} height={152} />
              <Skeleton
                variant="text"
                width={120}
                sx={{ fontSize: "0.5rem", marginTop: "2rem" }}
              />
              <Skeleton
                variant="text"
                width={100}
                sx={{ fontSize: "0.5rem", marginTop: "2rem" }}
              />
            </Stack>
          ))}
        </Stack>
      ))}
    </div>
  );
}

const ProductCo = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { draftOrder } = location.state || {};
  const { store_id } = useSelector((state) => state?.login?.user);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState(
    draftOrder?.products || []
  ); // Use draftOrder products if available
  const [isScrollAtStart, setIsScrollAtStart] = useState(true);
  const [isScrollAtEnd, setIsScrollAtEnd] = useState(false);
  const [categories, setcategories] = useState([]);
  const Allcategory = useSelector(
    (state) => state.categoriesReducer.categories
  );
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const { OpenCashModal } = useSelector((state) => state?.sessionIdentify);
  const carouselRef = useRef(null);
  const searchInputRef = useRef(null);
  const [search, setsearch] = useState("");
  const [categoryid, setcategoryid] = useState(null);
  const [isfav, setisfav] = useState(false);
  const [isCartCleared, setIsCartCleared] = useState(false);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.login?.user?.result);
  const localProducts = useSelector((state) => state.products.products);
  const { selectedCustomer } = useSelector((state) => state.getcustomer);
  const [isCategorySelectionActive, setIsCategorySelectionActive] =
    useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [isScannerActive, setIsScannerActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userSelectedIndex, setUserSelectedIndex] = useState(null);
  const returnProducts = useSelector(
    (state) => state.returnProductReducer.products
  );
  const allProducts = useSelector(
    (state) => state.allProductsReducer.allProducts
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [prods, setProds] = useState([]);
  const [prodLoading, setprodLoading] = useState(false);
  const [favoriteCategoryId, setFavoriteCategoryId] = useState(null);
  const [isOrderButtonVisible, setIsOrderButtonVisible] = useState(false);
  // const [categoryid, setCategoryid] = useState(null);
  const [favoriteActive, setFavoriteActive] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [highlightedCategoryIndex, setHighlightedCategoryIndex] =
    useState(null);

  const [focusedProductIndex, setFocusedProductIndex] = useState(null);
  const [isProductSelectionActive, setIsProductSelectionActive] =
    useState(false);

  // const [isLocal, setIsLocal] = useState(false);
  const lcl = localStorage.getItem("localstorage");
  const isLocal = lcl == "true" ? true : false;

  //  console.log("ALLLLLLL PROD ------------->", allProducts.length);

  useEffect(() => {
    const fetchAllProducts = async () => {
      let mounted = true;
      const companyId = userData?.company_id;
      const lang = "en_US";
      const limit = 500;
      let offset = 0;
      let totalProducts = 0;
      let products = [];

      const fetchProducts = () => {
        axiosInstance
          .post(URLS.GET_ALL_PROD, {
            company_id: companyId,
            lang: lang,
            limit: limit,
            offset: offset,
          })
          .then((productResponse) => {
            if (productResponse?.data?.result) {
              const newProducts =
                productResponse?.data?.result[0]?.products || [];
              const response = productResponse?.data?.result[0]?.status;
              // console.log("PRODUCT LENGHTH .......>", newProducts.length);
              // console.log("PRODUCT Response .......>", response);
              if (response !== "No Products Available") {
                products = [...products, ...newProducts];
                offset += limit;
                totalProducts =
                  productResponse?.data?.result[0]?.total_products;
                if (products.length < totalProducts) {
                  fetchProducts();
                } else {
                  console.log(
                    "All products fetched:",
                    products.length,
                    totalProducts
                  );
                  // setIsLocal(true);
                  localStorage.setItem("localstorage", true);
                  dispatch(addAllProducts(products));
                }
              }
            }
          })
          .finally(() => {
            if (mounted) {
            }
          });
      };

      if (mounted && allProducts.length === 0) {
        fetchProducts();
      }

      return () => {
        mounted = false;
      };
    };

    fetchAllProducts();
  }, [userData]);

  useEffect(() => {
    console.log("llocccalllalal", typeof isLocal);

    if (isLocal === true) {
      getlocalProducts("", "TEXT", 0);
    } else {
      // console.log("USE EFFECT 1");

      getProducts("", "TEXT", 0);
    }
  }, [isLocal]);

  useEffect(() => {
    dashboardFunctions();
    // console.log("IS LOCAL ", isLocal);

    // localStorage.setItem("localstorage", false);
  }, []);

  useEffect(() => {
    if (isScannerActive) {
      setUserSelectedIndex(null);
    }
  }, [isScannerActive]);

  const dashboardFunctions = async () => {
    const isLangCalled = await setSystyemLang();
    if (isLangCalled) {
      setProductsLoading(true);
      // setProductsLoading(true);

      // getcategories();

      if (isLocal === true) {
        getlocalProducts("", "TEXT", 0);
      } else {
        // console.log("DASHBIARD FNC");

        getProducts("", "TEXT", 0);
      }
      // getlocalProducts("", "TEXT", 0);
      // setProductsLoading(true);
      // getProducts("", "TEXT", categoryid);
    }
  };

  useEffect(() => {
    if (currentCategory !== null && isLocal) {
      getlocalProducts("", "TEXT", currentCategory);
    }
    if (categoryid == "fav" && isLocal) {
      handleFavoriteCategoryClick();
    }
  }, [currentCategory, allProducts]);

  useEffect(() => {
    if (!isCartCleared && location.state && location.state.draftOrder) {
      dispatch(addProducts(location.state.draftOrder.products));
    }
  }, [location.state, isCartCleared]);

  useEffect(() => {
    if (!isCartCleared) {
    }
  }, [draftOrder, isCartCleared]);

  // console.log("CCCCCC", Allcategory);

  // console.log("CCCCCC", Allcategory.length);

  useEffect(() => {
    if (Allcategory.length <= 0) {
      const companyId = userData?.company_id;
      setCategoriesLoading(true);
      dispatch(fetchCategories(companyId));
      // console.log("EEE");
    }
    setcategories(Allcategory);
    setActiveIndex(0);
    setcategoryid(0);
    // handleFavoriteCategoryClick();
    setCategoriesLoading(false);

    //   }
  }, [dispatch, Allcategory]);

  // const getcategories = async () => {
  //   setCategoriesLoading(true);
  //   const response = await axiosInstance.post(URLS.GET_CATEGORIES, {
  //     company_id: userData?.company_id,
  //   });
  //   if (response?.data?.result[0]?.status_code === 200) {
  //     setcategories(response?.data?.result[0]?.response);
  //     setActiveIndex(0);
  //     setcategoryid("fav");
  //     handleFavoriteCategoryClick();
  //   }
  //   setCategoriesLoading(false);
  // };

  const setSystyemLang = async () => {
    const response = await axiosInstance.post(URLS.CHANGE_LANGUAGE, {
      code: i18n.language,
    });
    if (response?.data?.result?.status_code === 200) {
      // console.log("Language resp===>", response?.data);

      return true;
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleFavoriteCategoryClick = () => {
    // setCurrentCategory("fav");
    setcategoryid("fav"); // Set categoryid to "fav" when favorite is clicked
    setFavoriteActive(true);
    setSelectedCategory("fav");
    if (isLocal) {
      getlocalProducts("", "TEXT", "fav", true);
    } else {
      getProducts("", "TEXT", 0, true);
    }
  };

  const getProducts = async (val, searchBy, cat_id, isFavorite = false) => {
    if (isLocal === true) {
      getlocalProducts(val, searchBy, cat_id);
    } else {
      let payload;

      const existingProductIndex = localProducts?.findIndex(
        (item) => item.barcode === val
      );

      if (existingProductIndex !== -1 && searchBy === "BARCODE") {
        const product = localProducts[existingProductIndex];
        handleSelectProduct(product);
        return;
      }
      if (searchBy === "TEXT") {
        setProductsLoading(true);
        payload = {
          company_id: userData.company_id,
          parent_category_id: cat_id || 0,
          product_name: val,
          is_favorite: isFavorite,
          barcode: "",
          limit: 50,
          offset: 0,
          lang: i18n.language === "ar" ? "ar_001" : "en_US",
        };
      } else {
        payload = {
          company_id: userData.company_id,
          parent_category_id: cat_id || 0,
          product_name: "",
          is_favourite: isFavorite,
          barcode: val,
          limit: 50,
          offset: 0,
          lang: i18n.language === "ar" ? "ar_001" : "en_US",
        };
      }
      if (val === "" || val?.length > 2) {
        const response = await axiosInstance.post(
          URLS.GET_CATEGORY_PROD,
          payload
        );
        if (response?.data?.result[0]?.response?.length > 0) {
          if (searchBy === "BARCODE") {
            // setProductsLoading(true);
            handleSelectProduct(response?.data?.result[0]?.response[0]);
          } else {
            setProductsLoading(true);
            setProducts(response?.data?.result[0]?.response);
          }
        } else {
          setProductsLoading(false);
          if (searchBy != "BARCODE") {
            setProducts([]);
          }
          if (searchBy === "BARCODE") {
            toast.error(t("Product Not Found"));
          }
        }
      } else {
        setProducts([]);
      }
      setProductsLoading(false);
      setIsScannerActive(false);
    }
  };

  const getlocalProducts = (text, searchBy, id, isFavorite = false) => {
    setProductsLoading(true);
    // console.log("id==.", id);
    if (allProducts.length > 0) {
      if (text.length > 3) {
        setCurrentCategory(null);
        setProductsLoading(true);
        const searchedProducts = allProducts.filter(
          (product) =>
            (i18n.language === "ar" &&
              product.name_ar &&
              product.name_ar.toLowerCase().includes(text.toLowerCase())) ||
            (product.name &&
              product.name.toLowerCase().includes(text.toLowerCase())) ||
            (product.barcode && String(product.barcode).includes(text))
        );
        const uniqueSearchedProducts = searchedProducts.filter(
          (product, index, self) =>
            index === self.findIndex((p) => p.prod_id === product.prod_id)
        );
        setProducts(uniqueSearchedProducts);
        setProductsLoading(false);
        return;
      } else if (id === "fav" && isFavorite) {
        const favoriteProducts = allProducts.filter(
          (product) => product.is_favorite
        );

        const uniqueFavoriteProducts = favoriteProducts.filter(
          (product, index, self) =>
            index === self.findIndex((p) => p.prod_id === product.prod_id)
        );
      
        setProducts(uniqueFavoriteProducts);
        setProductsLoading(false);
        // setProducts(favoriteProducts.slice(0, 50));
        return;
      } else if (id != 0) {
        // console.log("id hai", id);
        // setProductsLoading(true);
        // Fetch products by category ID
        const categoryProducts = allProducts?.filter(
          (product) => product.product_main_categ === id
        );
        setProducts(categoryProducts);
        setProductsLoading(false);
        // setProducts(categoryProducts?.slice(0, 50));
        return;
      } else if (id === null || id === 0) {
        // console.log("id nhi hai", id);

        // setProductsLoading(true);
        // const randomProducts = allProducts?.slice(0, 50);
        const randomProducts = allProducts;
        // setProducts(randomProducts);
        setProducts(randomProducts.slice(0, 100));
        setProductsLoading(false);
        return;
      } else {
        // console.log("kuch nhi");
        setProducts(allProducts);
        setProductsLoading(false);
        // setProducts(allProducts.slice(0, 50));
        return;
      }
    } else {
      // console.log("length nhi hai");
      setProducts([]);
      setProductsLoading(false);
      return;
    }
    setProductsLoading(false);
  };

  // const getProdbybarcode = (text) => {
  //   // console.log("text", text);
  //   setIsCategorySelectionActive(false);
  //   setHighlightedCategoryIndex(0);

  //   const existingProductIndex = allProducts?.findIndex(
  //     (item) => item.barcode === text
  //   );

  //   if (existingProductIndex !== -1) {
  //     const product = allProducts[existingProductIndex];
  //     handleSelectProduct(product);
  //     return;
  //   }
  //   const product = allProducts?.find((prod) => prod.barcode === text);

  //   if (product) {
  //     handleSelectProduct(product);
  //     if (categoryid != 0) {
  //       setProducts(allProducts?.slice(0, 50));
  //     }
  //   } else {
  //     toast.error(t("Product Not Found"));
  //   }
  //   setIsScannerActive(false);
  // };

  const getProdbybarcode = (text) => {
    setIsCategorySelectionActive(false);
    setHighlightedCategoryIndex(0);

    // First, search for the barcode in allProducts.prod.barcode
    const existingProductIndex = allProducts?.findIndex(
      (item) => item.barcode === text
    );

    if (existingProductIndex !== -1) {
      const product = allProducts[existingProductIndex];
      handleSelectProduct(product);
      return;
    }

    const firstSevenDigits = text.substring(0, 7);
    const product = allProducts?.find((prod) => {
      if (prod.machine_barcode) {
        return prod.machine_barcode.startsWith(firstSevenDigits);
      }
      return false;
    });

    if (product) {
      handleSelectProduct(product, text);
      if (categoryid != 0) {
        setProducts(allProducts?.slice(0, 50));
      }
    } else {
      toast.error(t("Product Not Found"));
    }
    setIsScannerActive(false);
  };

  const debouncedGetProducts = debounce((val, searchBy, cat_id) => {
    // setProductsLoading(true);

    setActiveIndex(0);
    setcategoryid(0);

    if (isLocal) {
      getlocalProducts(val, searchBy, 0);
    } else {
      getProducts(val, searchBy, 0);
    }
  }, 500);

  const handleNewOrderClick = () => {
    if (localProducts && Object.keys(localProducts).length > 0) {
      const now = new Date();
      const draftOrder = {
        id: Date.now(),
        id: Date.now(),
        customer: selectedCustomer ? selectedCustomer : undefined,
        items: localProducts,
        date: now.toISOString().split("T")[0],
        time: now.toTimeString().split(" ")[0],
        date: now.toISOString().split("T")[0],
        time: now.toTimeString().split(" ")[0],
        total: Object.values(localProducts).reduce(
          (total, item) => total + item.sale_price * item.quantity,
          0
        ),
      };
      dispatch(addDraftOrder(draftOrder));
      // console.log("CHLAAAA");
      dispatch(clearProducts());
      dispatch(clearReturnProducts());
      setFocusedProductIndex(false);
      // setIsCategorySelectionActive(true);
      // setHighlightedCategoryIndex(1);
      setIsProductSelectionActive(false);
      dispatch(clearCustomer());
    }
  };

  // const handleSelectProduct = (product, text) => {
  //   if (!Array.isArray(localProducts)) {
  //     console.error("localProducts is not an array:", localProducts);
  //     return;
  //   }
  
  //   const updatedProducts = [...localProducts];
  //   const existingProductIndex = updatedProducts.findIndex(
  //     (p) => p.prod_id === product.prod_id
  //   );
  
  //   if (existingProductIndex !== -1) {
  //     if (text && text.length > 6 && product.machine_barcode.startsWith(text.substring(0, 6))) {
  //       setSelectedProducts(updatedProducts[existingProductIndex]);
  //     } else {
  //       updatedProducts[existingProductIndex].quantity += 1;
  //       setSelectedProducts(updatedProducts[existingProductIndex]);
  //     }
  //   } else {
  //     console.log("quantity--------->",product.quantity);
  //     let quantity = 1;
  //     if (text && text.length > 6 && product.machine_barcode.startsWith(text.substring(0, 6))) {
        
  //       quantity = parseInt(text.substring(text.length - 6));
  //       quantity = quantity / 1000;

  //     }
  //     updatedProducts.push({ ...product, quantity: quantity });
  //     setSelectedProducts(updatedProducts[updatedProducts.length - 1]);
  //   }
  //   dispatch(addProducts(updatedProducts));
  //   setSelectedProducts(updatedProducts);
  //   setUserSelectedIndex(
  //     updatedProducts.findIndex((p) => p.prod_id === product.prod_id)
  //   );
  // };

  const handleSelectProduct = (product, text) => {
    if (!Array.isArray(localProducts)) {
      console.error("localProducts is not an array:", localProducts);
      return;
    }

    if (
      (product.machine_barcode || product.weight) &&
      (!text || text.length !== 13)
    ) {
      toast.error(t("Please check the barcode"));
      return;
    }
  
    const updatedProducts = [...localProducts];
    const existingProductIndex = updatedProducts.findIndex(
      (p) => p.prod_id === product.prod_id
    );
  
    if (existingProductIndex !== -1) {
      if (text && text.length > 6 && product.machine_barcode.startsWith(text.substring(0, 6))) {
        const weightInKg = parseInt(text.substring(text.length - 6)) / 1000; 
      const updatedQuantity = updatedProducts[existingProductIndex].quantity + weightInKg;
      updatedProducts[existingProductIndex].quantity = parseFloat(updatedQuantity.toFixed(3));
      setSelectedProducts(updatedProducts[existingProductIndex]);
        // setSelectedProducts(updatedProducts[existingProductIndex]);
      } else {
        if (product.weight) {
          // updatedProducts[existingProductIndex].quantity = product.weight/1000;
          const weightInKg = product.weight / 1000;
        updatedProducts[existingProductIndex].quantity += weightInKg;
        setSelectedProducts(updatedProducts[existingProductIndex]);
          // setSelectedProducts(updatedProducts[existingProductIndex]);
        } else {
          updatedProducts[existingProductIndex].quantity += 1;
          setSelectedProducts(updatedProducts[existingProductIndex]);
        }
      }
    } else {
      // console.log("quantity--------->", product.quantity);
      let quantity = 1;
      if (text && text.length > 6 && product.machine_barcode.startsWith(text.substring(0, 6))) {
        quantity = parseInt(text.substring(text.length - 6));
        quantity = quantity / 1000; // Convert to grams
      } else if (product.weight) {
        quantity = product.weight / 1000;
      }
      updatedProducts.push({ ...product, quantity: quantity });
      setSelectedProducts(updatedProducts[updatedProducts.length - 1]);
    }
    dispatch(addProducts(updatedProducts));
    setSelectedProducts(updatedProducts);
    setUserSelectedIndex(
      updatedProducts.findIndex((p) => p.prod_id === product.prod_id)
    );
  };

  // const handleSelectProduct = (product, text) => {
  //   if (!Array.isArray(localProducts)) {
  //     console.error("localProducts is not an array:", localProducts);
  //     return;
  //   }
  //   // const isMachineCodeSearch =
  //   //   text.length > 7 && product.machine_barcode.startsWith(text.substring(0, 7));

  //   // if (isMachineCodeSearch) {
  //   //   // Extract the last seven digits of the input text and set the quantity
  //   //   const quantity = parseInt(text.substring(text.length - 7));
  //   //   product.quantity = quantity;
  //   // }

  //   const updatedProducts = [...localProducts];
  //   const existingProductIndex = updatedProducts.findIndex(
  //     (p) => p.prod_id === product.prod_id
  //   );
  //   if (existingProductIndex !== -1) {
  //     updatedProducts[existingProductIndex].quantity += 1;
  //     setSelectedProducts(updatedProducts[existingProductIndex]);
  //   } else {
  //     updatedProducts.push({ ...product, quantity: 1 });
  //     setSelectedProducts(updatedProducts[updatedProducts.length - 1]);
  //   }
  //   dispatch(addProducts(updatedProducts));
  //   setSelectedProducts(updatedProducts);
  //   setUserSelectedIndex(
  //     updatedProducts.findIndex((p) => p.prod_id === product.prod_id)
  //   );
  // };

  const handleRemoveProduct = (product) => {
    if (!Array.isArray(localProducts)) {
      console.error("localProducts is not an array:", localProducts);
      return;
    }
    const updatedProducts = localProducts.filter(
      (p) => p.prod_id !== product.prod_id
    );
    const previousProduct = updatedProducts[updatedProducts.length - 1];
    const previousProductIndex = updatedProducts.findIndex(
      (p) => p.prod_id === previousProduct.prod_id
    );
    setSelectedProducts(
      previousProductIndex !== -1 ? updatedProducts[previousProductIndex] : null
    );
    dispatch(addProducts(updatedProducts));
    setSelectedProducts(updatedProducts);
    setUserSelectedIndex(
      updatedProducts.length > 0 ? updatedProducts.length - 1 : null
    );
  };

  const handleUpdateQuantity = (productId, quantity) => {
    const updatedProducts = [...localProducts];
    const existingProductIndex = updatedProducts.findIndex(
      (p) => p.prod_id === productId
    );
    if (existingProductIndex !== -1) {
      updatedProducts[existingProductIndex].quantity = Math.max(quantity, 0);
    }
    dispatch(addProducts(updatedProducts));
    setSelectedProducts(updatedProducts);
  };

  // const handleCategoryClick = (category) => {
  //   console.log("category", category);
  //   setCurrentCategory(category);
  //   setcategoryid(category);
  //   setSelectedCategory(category);
  //   setProductsLoading(true);

  //   if (isLocal){
  //     getlocalProducts("", "TEXT", category);
  //   } else {
  //     getProducts("", "TEXT", category)
  //   }
  // };

  const handleCategoryClick = (category) => {
    console.log("category", category);
    setCurrentCategory(category);
    setcategoryid(category);
    setSelectedCategory(category);
    setProductsLoading(true);

    if (isLocal) {
      if (category === "fav") {
        getlocalProducts("", "TEXT", "fav", true);
      } else {
        getlocalProducts("", "TEXT", category);
      }
    } else {
      if (category === "fav") {
        setProducts([]);
      } else {
        getProducts("", "TEXT", category);
      }
    }
  };

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
    setIsScrollAtStart(scrollLeft === 0);
    setIsScrollAtEnd(scrollLeft + clientWidth === scrollWidth);
  };

  const scrollToPrevItem = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollToNextItem = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const keyMappings = {
    N: ["N", "آ"],
    S: ["S", "ٍ"],
    ArrowRight: ["ArrowRight", "ArrowRight"],
    ArrowLeft: ["ArrowLeft", "ArrowLeft"],
    Backspace: ["Backspace", "ي"],
    Delete: ["Delete", "حذف"],
    Enter: ["Enter", "إدخال"],
    " ": [" ", " "],
    ArrowDown: ["ArrowDown", "تحت"],
  };

  const isKeyMatch = (key, targets) => targets.includes(key);

  const handleKeyDown = (e) => {
    const key = e.key;

    if (e.shiftKey && isKeyMatch(key, keyMappings.N)) {
      e.preventDefault();
      handleNewOrderClick();
      searchInputRef.current.blur();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleNewOrderClick]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (prodLoading == false) {
        const key = e.key;

        if (e.ctrlKey && isKeyMatch(key, keyMappings.Enter)) {
          e.preventDefault();
          setIsCategorySelectionActive(false);
          setIsProductSelectionActive(false);
          setUserSelectedIndex(false);
          searchInputRef.current.blur();
        }

        if (e.shiftKey && isKeyMatch(key, keyMappings.ArrowRight)) {
          e.preventDefault();
          setFocusedProductIndex(false);
          setIsCategorySelectionActive(true);
          setHighlightedCategoryIndex(0);
          setIsProductSelectionActive(false);
          searchInputRef.current.blur();
        }

        if (e.shiftKey && isKeyMatch(key, keyMappings.Backspace)) {
          e.preventDefault();
          dispatch(clearProducts());
          setFocusedProductIndex(false);
          setIsProductSelectionActive(false);
        }

        if (isKeyMatch(key, keyMappings.Delete)) {
          e.preventDefault();
          setFocusedProductIndex(false);
          setIsProductSelectionActive(false);
        }

        if (isCategorySelectionActive) {
          if (isKeyMatch(key, keyMappings.ArrowRight)) {
            e.preventDefault();
            setFocusedProductIndex(false);

            if (i18n.language === "ar") {
              setHighlightedCategoryIndex((prev) => Math.max(prev - 1, -1));
            } else {
              setHighlightedCategoryIndex((prev) =>
                Math.min(prev + 1, categories.length)
              );
            }

            const carousel = carouselRef.current;
            const newHighlightedCategoryIndex =
              i18n.language === "ar"
                ? highlightedCategoryIndex - 1
                : highlightedCategoryIndex + 1;
            const selectedCategoryElement =
              carousel.children[newHighlightedCategoryIndex - 1];

            if (selectedCategoryElement) {
              const scrollPosition = carousel.scrollLeft;
              const categoryWidth = selectedCategoryElement.offsetWidth;

              if (i18n.language === "ar") {
                if (
                  scrollPosition + carousel.offsetWidth <
                  categoryWidth * newHighlightedCategoryIndex
                ) {
                  carousel.scrollBy({
                    left: categoryWidth * 4,
                    behavior: "smooth",
                  });
                }
              } else {
                if (
                  scrollPosition + carousel.offsetWidth <
                  categoryWidth * newHighlightedCategoryIndex
                ) {
                  carousel.scrollBy({
                    left: categoryWidth * 4,
                    behavior: "smooth",
                  });
                }
              }
            }
          }

          if (isKeyMatch(key, keyMappings.ArrowLeft)) {
            e.preventDefault();
            setFocusedProductIndex(false);

            if (i18n.language === "ar") {
              setHighlightedCategoryIndex((prev) =>
                Math.min(prev + 1, categories.length)
              );
            } else {
              setHighlightedCategoryIndex((prev) => Math.max(prev - 1, -1));
            }

            const carousel = carouselRef.current;
            const newHighlightedCategoryIndex =
              i18n.language === "ar"
                ? highlightedCategoryIndex + 1
                : highlightedCategoryIndex - 1;
            const selectedCategoryElement =
              carousel.children[newHighlightedCategoryIndex - 1];

            if (selectedCategoryElement) {
              const scrollPosition = carousel.scrollLeft;
              const categoryWidth = selectedCategoryElement.offsetWidth;

              if (i18n.language === "ar") {
                if (
                  scrollPosition <
                  categoryWidth * (newHighlightedCategoryIndex - 1)
                ) {
                  carousel.scrollBy({
                    left: -categoryWidth * 1,
                    behavior: "smooth",
                  });
                }
              } else {
                if (
                  scrollPosition <
                  categoryWidth * (newHighlightedCategoryIndex - 1)
                ) {
                  carousel.scrollBy({
                    left: -categoryWidth * 2,
                    behavior: "smooth",
                  });
                }
              }
            }
          }

          if (isKeyMatch(key, keyMappings.Enter)) {
            e.preventDefault();
            const selectedCategoryIndex = highlightedCategoryIndex - 1;
            if (selectedCategoryIndex === -2) {
              handleCategoryClick("fav"); // Handle "Favourites" category separately
            } else if (selectedCategoryIndex === -1) {
              handleCategoryClick(0); // Handle "All" category separately
            } else {
              const selectedCategory = categories[selectedCategoryIndex];
              handleCategoryClick(selectedCategory.category_id);
            }
          }
        }

        if (e.shiftKey && isKeyMatch(key, keyMappings.ArrowDown)) {
          e.preventDefault();
          setIsCategorySelectionActive(false);
          setIsProductSelectionActive(true);
          setFocusedProductIndex(0);
          searchInputRef.current.blur();
        }

        if (isProductSelectionActive) {
          if (isKeyMatch(key, keyMappings.ArrowRight)) {
            e.preventDefault();
            if (i18n.language === "ar") {
              setFocusedProductIndex((prev) => Math.max(prev - 1, 0));
            } else {
              setFocusedProductIndex((prev) =>
                Math.min(prev + 1, products.length - 1)
              );
            }
          }

          if (isKeyMatch(key, keyMappings.ArrowLeft)) {
            e.preventDefault();
            if (i18n.language === "ar") {
              setFocusedProductIndex((prev) =>
                Math.min(prev + 1, products.length - 1)
              );
            } else {
              setFocusedProductIndex((prev) => Math.max(prev - 1, 0));
            }
          }

          if (isKeyMatch(key, keyMappings[" "])) {
            e.preventDefault();
            if (focusedProductIndex !== null) {
              handleSelectProduct(products[focusedProductIndex]);
            }
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [
    isCategorySelectionActive,
    highlightedCategoryIndex,
    categories,
    isProductSelectionActive,
    focusedProductIndex,
    products,
    isScannerActive,
    handleSelectProduct,
  ]);

  useEffect(() => {
    if (prodLoading == false) {
      const keyMap = {
        S: ["S", "ٍ"],
      };

      const normalizeKey = (key) => {
        const normalizedKeys = {
          "ٍ": "S", // Arabic "س" is similar to English "S"
          // Add more mappings if needed
        };
        return normalizedKeys[key] || key;
      };

      const handleKeyDown = (e) => {
        const normalizedKey = normalizeKey(e.key);

        if (e.shiftKey && keyMap.S.includes(normalizedKey)) {
          e.preventDefault();
          // console.log("Shift + S detected");
          if (searchInputRef.current) {
            searchInputRef.current.focus();
          }
          setIsProductSelectionActive(false);
          setIsCategorySelectionActive(false);
          setFocusedProductIndex(null);
          setHighlightedCategoryIndex(0); // Start with "All"
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [prodLoading]);

  // const handleRefresh = () => {
  //   const companyId = userData?.company_id;
  //   const lang = "en_US";

  //   console.log("companyId:", companyId); // Add this line

  //   setprodLoading(true); // start loading

  //   axiosInstance
  //     .post(URLS.GET_ALL_PROD, {
  //       company_id: companyId,
  //       lang: lang,
  //     })
  //     .then((productResponse) => {
  //       if (productResponse?.data?.result[0]?.status_code === 200) {
  //         dispatch(
  //           addAllProducts(productResponse?.data?.result[0]?.products || [])
  //         );
  //         setprodLoading(false);
  //       }
  //     })
  //     .finally(() => {
  //       setprodLoading(false);
  //       handleCategoryClick(0, 0, true);
  //       if (searchInputRef.current) {
  //         searchInputRef.current.value = "";
  //       }
  //     });
  // };

  // const handleRefresh = () => {
  //   const companyId = userData?.company_id;
  //   // const lang = "en_US";

  //   setprodLoading(true); // start loading

  //   axiosInstance
  //     .post(URLS.UPDATE_PROD, {
  //       company_id: companyId,
  //       // lang: lang,
  //     })
  //     .then((productResponse) => {
  //       if (productResponse?.data?.result[0]?.status_code === 200) {
  //         const updatedProducts =
  //           productResponse?.data?.result[0]?.products || [];
  //         // const allProducts = useSelector((state) => state.allProductsReducer.allProducts);

  //         const updatedAllProducts = allProducts.map((product) => {
  //           const updatedProduct = updatedProducts.find(
  //             (p) => p.prod_id === product.prod_id
  //           );

  //           return updatedProduct ? { ...product, ...updatedProduct } : product;
  //         });

  //         dispatch(updateAllProducts(updatedAllProducts));
  //         setProducts(updatedAllProducts);
  //         handleCategoryClick(0, 0, true);
  //         // handleFavoriteCategoryClick()
  //         // handleCategoryClick(0, 0, true);
  //         setprodLoading(false);
  //       }
  //     })
  //     .finally(() => {
  //       setprodLoading(false);
  //       // handleCategoryClick(0, 0, true);
  //       if (searchInputRef.current) {
  //         searchInputRef.current.value = "";
  //       }
  //     });
  // };
  // console.log("ALL PRODUCT==>",allProducts);

  const handleRefresh = () => {
    const companyId = userData?.company_id;
  
    setprodLoading(true); // start loading
  
    axiosInstance
      .post(URLS.UPDATE_PROD, {
        company_id: companyId,
      })
      .then((productResponse) => {
        if (productResponse?.data?.result[0]?.status_code === 200) {
          const updatedProducts = productResponse?.data?.result[0]?.products || [];
  
          // Update existing products and collect the product codes for comparison
          const updatedAllProducts = allProducts.map((product) => {
            const updatedProduct = updatedProducts.find(
              (p) => p.prod_id === product.prod_id
            );
  
            return updatedProduct ? { ...product, ...updatedProduct } : product;
          });
  
          // Find products from updatedProducts that are not already in allProducts
          const newProducts = updatedProducts.filter(
            (updatedProduct) =>
              !allProducts.some((product) => product.prod_id === updatedProduct.prod_id)
          );
  
          // Combine updated and new products
          const finalProductList = [...updatedAllProducts, ...newProducts];

          console.log('FFFFFFFFFFF1', finalProductList);
          console.log('FFFFFFFFFFF2', updatedAllProducts);
          console.log('FFFFFFFFFFF3', newProducts);


          
  
          // Dispatch and set state
          dispatch(updateAllProducts(finalProductList));
          setProducts(finalProductList);
          handleCategoryClick(0, 0, true);
          setprodLoading(false);
        }
      })
      .finally(() => {
        setprodLoading(false);
        if (searchInputRef.current) {
          searchInputRef.current.value = "";
        }
      });
  };
  

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (prodLoading) {
        e.preventDefault();
      }
    };

    if (prodLoading) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [prodLoading]);

  return (
    <>
      {OpenCashModal && (
        <OpeningCash_Modal
          onClose={() => {
            dispatch(sessionstatesave(false));
          }}
        />
      )}

      {prodLoading && (
        <ProdLoadingSpinner
          message={t("Please wait, while we are updating your data")}
        />
      )}

      <div className="grid grid-cols-3 bg-[#EEEEEE] mt-2 gap-0 me-5 ">
        {/* <div className=""> */}
        <ProductBox
          draftOrder={draftOrder}
          products={localProducts}
          userSelectedIndex={userSelectedIndex}
          setUserSelectedIndex={setUserSelectedIndex}
          onRemoveProduct={handleRemoveProduct}
          onUpdateQuantity={handleUpdateQuantity}
          isScannerActive={isScannerActive}
          returnProducts={returnProducts}
          prodLoading={prodLoading}
          // onClearCart={handleClearCart}
        />

        {/* </div> */}

        <div className="flex col-span-2 justify-start ps-4">
          <div className="flex flex-col ps-6 w-full max-w-[100%]">
            <div className="flex flex-row justify-start gap-5">
              <div style={{ width: isOrderButtonVisible ? "80%" : "100%" }}>
                <ProductSearchBar
                  // value={search}
                  ref={searchInputRef}
                  onSearch={(e) => {
                    const value = e.target.value;
                    // handleCategoryClick(0, 0)
                    // setsearch(value);
                    setSelectedCategory(0);
                    setIsProductSelectionActive(false);
                    setFocusedProductIndex(null);
                    debouncedGetProducts(value, "TEXT", categoryid);
                  }}
                  id="searchBar"
                />
              </div>
              {/* <Link to="/orders" className="nav-link"> */}
              {/* <ReturnButton /> */}
              {/* <ReturnButton /> */}
              {/* </Link> */}
              {Object.keys(localProducts).length > 0 && (
                <NewOrderButton onClick={handleNewOrderClick} />
              )}
              <button
                className="flex flex-row first-letter:justify-center items-center cursor-pointer w-[30px] h-[30px] mt-2"
                onClick={handleRefresh}
              >
                <img
                  loading="lazy"
                  src={refresh_icon}
                  alt="refresh_icon"
                  className="w-[40px] h-[35px] "
                />
              </button>
            </div>
            <div className="flex flex-row items-center ">
              {!isScrollAtStart && (
                <button
                  className={`mt-2 ${
                    i18n.language === "ar"
                      ? "bg-gradient-to-r from-[#EEEEEE] to-[#7EC242]"
                      : "bg-gradient-to-l from-[#EEEEEE] to-[#7EC242]"
                  } font-bold focus:outline-none hover:${
                    i18n.language === "ar"
                      ? "bg-gradient-to-r"
                      : "bg-gradient-to-l"
                  } rounded-lg p-2`}
                  onClick={() =>
                    i18n.language === "ar"
                      ? scrollToNextItem()
                      : scrollToPrevItem()
                  }
                  // onClick={scrollToPrevItem}
                >
                  <img
                    src={i18n.language === "ar" ? right_scroll : left_scroll}
                    className="h-6 w-12"
                    alt="Scroll Icon"
                  />
                </button>
              )}
              <div
                className="flex overflow-x-auto scrollbar-hidden"
                ref={carouselRef}
                onScroll={handleScroll}
              >
                {categoriesLoading ? (
                  <CategorySkeleton />
                ) : (
                  <>
                    <CategoryButton
                      active={categoryid === "fav"}
                      highlighted={highlightedCategoryIndex === -1}
                      onClick={handleFavoriteCategoryClick}
                    >
                      {/* {t("Favourite")} */}
                      <img
                        className="h-5 mb-0.5 w-auto justify-center items-center"
                        src={star_icon}
                        alt="Favourite"
                      />
                    </CategoryButton>
                    <CategoryButton
                      active={categoryid === 0}
                      highlighted={highlightedCategoryIndex === 0}
                      onClick={() => handleCategoryClick(0, 0, true)}
                    >
                      {t("All")}
                    </CategoryButton>
                    {categories?.map((category, index) => (
                      <CategoryButton
                        key={index}
                        active={selectedCategory === category.category_id}
                        highlighted={highlightedCategoryIndex === index + 1}
                        onClick={() =>
                          handleCategoryClick(category.category_id)
                        }
                      >
                        {/* {category.name} */}
                        {i18n.language === "ar"
                          ? category.name_ar
                          : category.name}
                      </CategoryButton>
                      // <CategoryButton
                      //   key={index}
                      //   active={categoryid === category?.category_id}
                      //   highlighted={highlightedCategoryIndex === index + 1}
                      //   onClick={() =>
                      //     handleCategoryClick(index + 1, category?.category_id)
                      //   }
                      // >
                      //   {category.name}
                      // </CategoryButton>
                    ))}
                  </>
                )}
              </div>
              {!isScrollAtEnd && (
                <button
                  className={`mt-2 ${
                    i18n.language === "ar"
                      ? "bg-gradient-to-l"
                      : "bg-gradient-to-r"
                  } from-[#EEEEEE] to-[#7EC242] rounded-lg p-2`}
                  onClick={() =>
                    i18n.language === "ar"
                      ? scrollToPrevItem()
                      : scrollToNextItem()
                  }
                  // onClick={scrollToNextItem}
                >
                  <img
                    src={i18n.language === "ar" ? left_scroll : right_scroll}
                    className="h-6 w-12"
                    alt="Scroll Icon"
                  />
                </button>
              )}
            </div>
            {productsLoading ? (
              <ProductGridSkeleton />
            ) : (
              <>
                <ProductGrid
                  onSelectProduct={handleSelectProduct}
                  products={products}
                  focusedProductIndex={focusedProductIndex}
                  productsLoading={productsLoading}
                />
              </>
            )}
          </div>
        </div>
        <BarcodeReader
          onScan={(data) => {
            // setIsScannerActive(true);
            // setFocusedProductIndex(null);
            setsearch("");
            setcategoryid(0);
            setSelectedCategory(0);
            if (isLocal) {
              getProdbybarcode(data);
            } else {
              getProducts(data, "BARCODE", 0);
            }
            // handleCategoryClick(0, 0);
            // setsearch("");
            // console.log("barcode called===>", data)
            // getProducts(data, "BARCODE", 0);
          }}
          onError={(err) => {
            console.error(err);
          }}
          facingMode="environment"
          // className="absolute inset-y-0 right-0 mt-2 mr-4 pointer-events-none cursor-pointer z-10"
        />
      </div>
    </>
  );
};

export default ProductCo;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openingCash } from "../../Redux/Action/loginAction";
import { useTranslation } from "react-i18next";
import { cash, opening_cash_icon } from "../../assets";
import { addAllProducts, fetchAllProducts } from "../../Redux/Action/allProducts";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";

// const LoadingSpinner = () => {
//   return (
//     <div
//       style={{ zIndex: 50 }}
//       className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 backdrop-blur"
//     >
//       <div className="spinner">
//         <span></span>
//         <span></span>
//         <span></span>
//       </div>
//     </div>
//   );
// };


const LoadingSpinner = ({ message }) => {
  return (
    <div
      style={{ zIndex: 50 }}
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-90 backdrop-blur"
    >
      <div className="flex flex-col items-center">
        <div className="text-lg text-white font-bold">{message}</div>
        <div className=" flex justify-center">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputField = ({ label, placeholder, value, onChange, disabled }) => {
  const { t, i18n } = useTranslation();
  const inputRef = useRef(null);

  // useEffect(() => {
  //   inputRef.current.focus();
  // }, []);

  useEffect(() => {
    if (!disabled) {
      inputRef.current.focus();
    }
  }, [disabled]);

  return (
    <>
      <div className="mt-9 font-medium max-md:max-w-full">{label}</div>
      <div
        style={{ direction: 'ltr' }}
        className="flex border-2 border-solid border-gray-300 rounded-xl overflow-hidden justify-center items-start"
      >
        <span className="px-3 py-3 bg-white text-black max-md:pe-3">EGP</span>
        <input
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled} 
          className="p-3 bg-white rounded-xl text-left border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 w-full focus:outline-none focus:border-2px"
        />
      </div>
    </>
  );
};

const OpeningCash_Modal = ({ onClose }) => {
  const [inputValue, setInputValue] = useState("");
  const userData = useSelector((state) => state?.login?.user?.result);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const allProducts = useSelector(
    (state) => state.allProductsReducer.allProducts
  );


  // console.log("UserData", userData);

  const inputFields = [
    { label: "Amount", placeholder: "Enter Amount" },
  ];

  const handleConfirm = async () => {
    // if (allProducts.length > 0)
    setLoading(true)
    try {
      const response = await dispatch(openingCash(userData?.session_id, inputValue));
      if (response === true) {
        onClose();
        setLoading(false)
      }
    } catch (error) {
      console.error("Error", error.response.data);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleConfirm();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleConfirm]);

  // useEffect(() => {
  //   let mounted = true;
  //   const companyId = userData?.company_id;
  //   const lang = "en_US";
  
  //   if (mounted && allProducts.length === 0) {
  //     setLoading(true); // start loading
  //     setLoadingProgress(0); // reset progress
  
  //     axiosInstance.post(URLS.GET_ALL_PROD, {
  //       company_id: companyId,
  //       lang: lang,
  //     }, {

  //     })
  //     .then((productResponse) => {
  //       if (productResponse?.data?.result[0]?.status_code === 200) {
  //         dispatch(addAllProducts(productResponse?.data?.result[0]?.products || []));
  //         setLoading(false);
  //       }
  //     })
  //     .finally(() => {
  //       if (mounted) {

  //        if (allProducts.length > 0) {
  //         setLoading(false); // stop loading
  //       }
  //       }
  //     });
  //   }
  
  //   return () => {
  //     mounted = false;
  //   };
  // }, [allProducts, userData]);
  
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      {loading &&
      <LoadingSpinner
      //  progress={loadingProgress}
       message={t("Please wait, we are fetching your data")} 
       />}

      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section className="relative flex flex-col justify-center items-center px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[40px] text-zinc-800 max-md:px-5 z-40" style={{ width: "600px" }}>
        <img src={opening_cash_icon} alt="" className="mb-10" />

        <div className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto">{t("Enter the Opening Cash")}</h1>
        </div>
        <div className="flex justify-center w-[100%] mt-10">
          {inputFields?.map((field, index) => (
            <InputField
              key={index}
              placeholder={t(field.placeholder)}
              value={inputValue}
              onChange={(e) => {
                let inputValue = e.target.value;
                inputValue = inputValue.replace(/ز/g, '.');

                inputValue = inputValue.replace(/ز/g, '.');

                if (inputValue?.match(/^\d*\.?\d{0,2}$/) && inputValue < 10000000) {
                  if (inputValue.length > 1 && inputValue.replace('.', '') === '0'.repeat(inputValue.replace('.', '').length)) {
                    return;
                  }
                  setInputValue(inputValue);
                }
              }}

              disabled={loading}  
            />
          ))}
        </div>
        <div className="flex justify-center mt-10 max-md:mt-10">
          <button
            type="submit"
            className="py-3.5 text-xl text-center text-white capitalize whitespace-nowrap bg-[#7EC242] rounded-lg w-[442px] max-md:px-5"
            onClick={handleConfirm}
            disabled={loading} 
          >
            {t("Confirm")}
          </button>
        </div>
      </section>
    </div>
  );
};

export default OpeningCash_Modal;
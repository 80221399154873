import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { updateAllProducts } from "./allProducts";

export const refreshProducts = () => {
    return async (dispatch, getState) => {
   
    const state = getState();
    const companyId = state?.login?.user?.result?.company_id
    //   console.log("COMP",companyId);
  
      try {
        const response = await axiosInstance.post(URLS.UPDATE_PROD, {
          company_id: companyId,
        });
  
        if (response.data.result[0].status_code === 200) {
          const updatedProducts = response.data.result[0].products || [];
          const allProducts = getState().allProductsReducer.allProducts;
  
          const updatedAllProducts = allProducts.map((product) => {
            const updatedProduct = updatedProducts.find((p) => p.prod_id === product.prod_id);
            return updatedProduct ? { ...product, ...updatedProduct } : product;
          });
  
          dispatch(updateAllProducts(updatedAllProducts));
        //   dispatch(setProducts(updatedAllProducts));
        //   dispatch(handleCategoryClick(0, 0, true));
        }
      } finally {
        // dispatch(setprodLoading(false));
      }
    };
  };
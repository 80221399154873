
import React, { useState } from "react";
 
function Weighting() {
  const [latestWeight, setLatestWeight] = useState("No data available");
 
  const fetchWeight = async () => {
    try {
      // Request access to the serial port
      const port = await navigator.serial.requestPort();
      await port.open({ baudRate: 9600 }); // Open the port with the correct baud rate
 
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable.getReader();
 
      // Read the data from the serial port
      const { value, done } = await reader.read();
      if (done) {
        console.log("Stream closed");
        reader.releaseLock();
        return;
      }
 
      // Process and display the data
      console.log("Weight data received:", value);
      setLatestWeight(value.trim());
 
      // Close the serial port
      await reader.cancel();
      await readableStreamClosed.catch(() => {}); // Ensure stream is closed
      await port.close();
      console.log("Serial port closed");
    } catch (error) {
      console.error("Error accessing serial port:", error.message);
    }
  };
 
  return (


<div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-blue-200 to-blue-400 p-4">
  <div class="bg-white shadow-md rounded-lg p-8 max-w-sm w-full text-center">
    <h1 class="text-3xl font-bold text-blue-600 mb-6">Weighing Machine</h1>
    <p class="text-lg text-gray-700 mb-4">Latest weight: <span class="font-semibold text-gray-900">{latestWeight}</span></p>
    <button 
      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition duration-300"
      onClick={fetchWeight}>
      Fetch Weight
    </button>
  </div>
</div>

  );
}
 
export default Weighting;

{/* <div className="App">
<h1>Weighing Machine</h1>
<p>Latest weight: {latestWeight}</p>
<button onClick={fetchWeight}>Fetch Weight</button>
</div> */}

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { CSVLink } from "react-csv";
// import SerialPort from "serialport";
// import ReadlineParser from "@serialport/parser-readline";

// function Weighting() {
//   const [weights, setWeights] = useState([]); // State to store all weights
//   const [currentWeight, setCurrentWeight] = useState("--"); // State for the current weight
//   const [loading, setLoading] = useState(false);
//   const [serialPort, setSerialPort] = useState(null); // State to store the serial port connection
//   const [latestWeight, setLatestWeight] = useState("No data available"); // State to store the latest weight data

//   useEffect(() => {
//     // Create a serial port connection when the component mounts
//     const serialPortConfig = {
//       path: "COM5", // Path to your COM port (update if needed)
//       baudRate: 9600,
//       autoOpen: false, // Prevent auto-opening until we handle errors
//     };

//     const serialPort = new SerialPort(serialPortConfig);
//     setSerialPort(serialPort);

//     const parser = new ReadlineParser({ delimiter: "\r\n" });
//     serialPort.pipe(parser);

//     parser.on("data", (data) => {
//       console.log("Weight data received:", data);
//       setLatestWeight(data.trim()); // Update the latest weight
//     });

//     return () => {
//       // Close the serial port connection when the component unmounts
//       serialPort.close((err) => {
//         if (err) {
//           console.error("Error closing serial port:", err.message);
//         } else {
//           console.log("Serial port COM5 closed successfully");
//         }
//       });
//     };
//   }, []);

//   // Fetch weight from the serial port when the button is clicked
//   const fetchWeight = async () => {
//     setLoading(true);
//     try {
//       setCurrentWeight(latestWeight);
//       setWeights([...weights, latestWeight]); // Update the weight list
//     } catch (error) {
//       console.error("Error fetching weight", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const csvData = [
//     ["Index", "Weight (kg)"],
//     ...weights.map((weight, index) => [index + 1, weight]),
//   ];

//   return (
//     <div className="app-container bg-gradient-to-r from-purple-500 to-indigo-600 min-h-screen flex flex-col items-center text-white p-8">
//       <header className="text-center w-full mb-8">
//         <h1 className="text-4xl font-bold mb-4">Weighing Machine</h1>
//         <div className="flex justify-center space-x-4">
//           <button
//             className="px-6 py-3 bg-yellow-400 text-purple-800 font-semibold rounded-lg hover:bg-yellow-300 transition duration-300"
//             onClick={fetchWeight} // Fetch weight when button is clicked
//             disabled={loading}
//           >
//             {loading ? "Loading..." : "Get Weight"}
//           </button>

//           {/* Export data as CSV */}
//           <CSVLink
//             data={csvData}
//             filename={"weights_data.csv"}
//             className="px-6 py-3 bg-green-400 text-purple-800 font-semibold rounded-lg hover:bg-green-300 transition duration-300"
//           >
//             Export Data
//           </CSVLink>
//         </div>

//         <div className="mt-6">
//           <h2 className="text-2xl bg-purple-700 px-8 py-4 rounded-lg shadow-md w-full text-center">
//             Current Weight: {currentWeight} kg
//           </h2>
//         </div>
//       </header>

//       <div className="w-full lg:w-3/5 overflow-y-auto bg-white text-gray-700 rounded-lg shadow-md">
//         <table className="min-w-full">
//           <thead className="bg-purple-600 text-white">
//             <tr>
//               <th className="py-2 px-4">#</th>
//               <th className="py-2 px-4">Weight (kg)</th>
//             </tr>
//           </thead>
//           <tbody id="weight-table-body" className="text-center">
//             {weights.length > 0 ? (
//               weights.map((weight, index) => (
//                 <tr key={index} className="bg-gray-100 hover:bg-gray-200">
//                   <td className="py-2 px-4">{index + 1}</td>
//                   <td className="py-2 px-4">{weight}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="2" className="py-2 px-4">
//                   No data available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default Weighting;

// import React, { useState } from "react";
// import axios from "axios";
// import { CSVLink } from "react-csv";
// // import "./App.css";

// function Weighting() {
//   const [weights, setWeights] = useState([]);  // State to store all weights
//   const [currentWeight, setCurrentWeight] = useState("--");  // State for the current weight
//   const [loading, setLoading] = useState(false);

//   // Fetch weight from the Node.js backend when the button is clicked
//   const fetchWeight = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get("http://localhost:5000/get_weight");  // API call to backend
//       const weight = response.data.weight;
//       if (!isNaN(weight)) {
//         setCurrentWeight(weight);
//         setWeights([...weights, weight]);  // Update the weight list
//       } else {
//         alert("Invalid weight data received.");
//       }
//     } catch (error) {
//       console.error("Error fetching weight", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const csvData = [
//     ["Index", "Weight (kg)"],
//     ...weights.map((weight, index) => [index + 1, weight]),
//   ];

//   return (
//     <div className="app-container bg-gradient-to-r from-purple-500 to-indigo-600 min-h-screen flex flex-col items-center text-white p-8">
//       <header className="text-center w-full mb-8">
//         <h1 className="text-4xl font-bold mb-4">Weighing Machine</h1>
//         <div className="flex justify-center space-x-4">
//           <button
//             className="px-6 py-3 bg-yellow-400 text-purple-800 font-semibold rounded-lg hover:bg-yellow-300 transition duration-300"
//             onClick={fetchWeight}  // Fetch weight when button is clicked
//             disabled={loading}
//           >
//             {loading ? "Loading..." : "Get Weight"}
//           </button>

//           {/* Export data as CSV */}
//           <CSVLink
//             data={csvData}
//             filename={"weights_data.csv"}
//             className="px-6 py-3 bg-green-400 text-purple-800 font-semibold rounded-lg hover:bg-green-300 transition duration-300"
//           >
//             Export Data
//           </CSVLink>
//         </div>

//         <div className="mt-6">
//           <h2 className="text-2xl bg-purple-700 px-8 py-4 rounded-lg shadow-md w-full text-center">
//             Current Weight: {currentWeight} kg
//           </h2>
//         </div>
//       </header>

//       <div className="w-full lg:w-3/5 overflow-y-auto bg-white text-gray-700 rounded-lg shadow-md">
//         <table className="min-w-full">
//           <thead className="bg-purple-600 text-white">
//             <tr>
//               <th className="py-2 px-4">#</th>
//               <th className="py-2 px-4">Weight (kg)</th>
//             </tr>
//           </thead>
//           <tbody id="weight-table-body" className="text-center">
//             {weights.length > 0 ? (
//               weights.map((weight, index) => (
//                 <tr key={index} className="bg-gray-100 hover:bg-gray-200">
//                   <td className="py-2 px-4">{index + 1}</td>
//                   <td className="py-2 px-4">{weight}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="2" className="py-2 px-4">
//                   No data available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default Weighting;
